import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { NobodySurfIcon } from "assets/icons"
import Image from "next/image"
import urlcat from "urlcat"

const ImageLoader = ({ src, quality }) => {
  return urlcat(src, { f: "auto", d: quality * 320 })
}

const RemoteImage = (props) => {
  const [loadImage, setLoadImage] = useState(false)
  useEffect(() => {
    setLoadImage(true)
  }, [loadImage])

  return (
    <div className={classNames("relative", props.className)}>
      {props.children}
      {loadImage && props.src && (
        <Image
          className="absolute top-0 left-0 z-10 object-cover w-full h-full"
          loader={ImageLoader}
          quality={props.scale}
          src={props.src}
          alt={props.alt}
          effect="opacity"
          layout="fill"
          wrapperClassName="align-top"
          onLoad={props.afterLoad}
        />
      )}
      {props.showIcon && (
        <NobodySurfIcon className="absolute z-0 w-10 h-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-element-disabled-light" />
      )}
    </div>
  )
}

RemoteImage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  src: PropTypes.string,
  alt: PropTypes.string,
  scale: PropTypes.number,
  showIcon: PropTypes.bool,
  afterLoad: PropTypes.func,
  beforeLoad: PropTypes.func,
}

RemoteImage.defaultProps = {
  showIcon: true,
  scale: 1,
}

export default RemoteImage
