import Link from "next/link"
import { node, string } from "prop-types"

const LinkHandler = (props) => {
  const { href, children, ...additionalProps } = props

  const isInternal = (href) =>
    href.startsWith("https://nobodysurf.com") ||
    href.startsWith("http://nobodysurf.com") ||
    href.startsWith("/")

  const isExternal = (href) => href.startsWith("http")

  if (href && isInternal(href)) {
    let path = href
      .replace("https://nobodysurf.com", "")
      .replace("http://nobodysurf.com", "")

    if (!path) {
      path = "/"
    }

    return (
      <Link href={path} prefetch={false}>
        <a {...additionalProps}>{children}</a>
      </Link>
    )
  }

  if (href && isExternal(href)) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...additionalProps}>
        {children}
      </a>
    )
  }

  return <div {...additionalProps}>{children}</div>
}

LinkHandler.propTypes = {
  href: string,
  children: node.isRequired,
}

export default LinkHandler
