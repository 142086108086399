import { node, number, string } from "prop-types"
import classNames from "classnames"
import RemoteImage from "components/UI/RemoteImage"
import CardVideoThumbnailCaption from "./Caption"

const CardVideoThumbnail = ({
  children,
  className,
  aspectRatio,
  thumbnailScale,
  image,
  alt,
  caption,
}) => (
  <div className={classNames(className, "flex-none")}>
    {children}

    <RemoteImage
      className={classNames(
        "bg-element-divider-light leading-zero align-top",
        `aspect-ratio-${aspectRatio}`,
      )}
      src={image}
      scale={thumbnailScale}
      alt={alt}>
      {caption && (
        <CardVideoThumbnailCaption
          caption={caption}
          className="absolute bottom-0 left-0 z-20 p-1"
        />
      )}
    </RemoteImage>
  </div>
)

CardVideoThumbnail.propTypes = {
  children: node,
  className: string,
  aspectRatio: string,
  image: string,
  alt: string,
  thumbnailScale: number,
  caption: string,
}

CardVideoThumbnail.defaultProps = {
  aspectRatio: "16/9",
}

export default CardVideoThumbnail
