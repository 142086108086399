import { string } from "prop-types"
import classNames from "classnames"

const CardVideoThumbnailCaption = ({ className, caption }) => (
  <span
    className={classNames(
      "text-element-extra-emphasis-dark tabular-nums fs-small-bold",
      className,
    )}>
    {caption}
  </span>
)

CardVideoThumbnailCaption.propTypes = {
  className: string,
  caption: string,
}

export default CardVideoThumbnailCaption
